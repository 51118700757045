@import "color.css";

/* .yellow-header {
  background: var(--themeMain);
} */
/* body {
  font-family: 'Montserrat', Arial, sans-serif;
} */

.icon {
  font-family: 'Bootstrap Icons', 'FontAwesome', sans-serif;
}
img {
  width: 100%;
}

.bg-thememain {
  background: var(--themeMain);
}

.header-main p {
  margin: 0px;
  color: var(--secondText);
}

.font-18 {
  font-size: 18px;
}

.botm-border {
  border-bottom: 1px solid var(--secondColor);
}

.card-details p {
  padding: 0px 8px;
}

.cat-text p {
  color: var(--themeMain);
  font-weight: 600;
}

.menu-clor a {
  color: #000;
  font-size: 17px;
  transition: 0.5s;
  padding: 3px 15px !important;
}

.menu-clor a:hover {
  color: #fff;
}

.hdphone:before {
  content: "\f414";
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-size: 42px;
  color: var(--firstText);
}

.fShipping:before {
  content: "\f844";
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-size: 42px;
  color: var(--secondColor);
  padding-right: 10px;
}

.moneyguarantee::before {
  content: "\F130";
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-size: 42px;
  color: var(--secondColor);
  padding-right: 10px;
}

.onlinesupport::before {
  content: "\f12a";
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-size: 42px;
  color: var(--secondColor);
  padding-right: 10px;
}

.securepayment::before {
  content: "\F407";
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-size: 42px;
  color: var(--secondColor);
  padding-right: 10px;
}

.memberDiscount::before {
  content: "\F636";
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-size: 42px;
  color: var(--secondColor);
  padding-right: 10px;
}

.userCart:before {
  content: "\F245";
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-size: 34px;
  border: 1px solid;
  border-radius: 50%;
  padding: 0px 9px;
  margin: 5px;
  color: var(--secondColor);
}

.header-cart-right {
  display: inline-block;
  font-size: 34px;
  object-fit: contain;
 
  /* border-radius: 10%; */
  padding: 0px 9px;
  margin: 5px;
  /* color: var(--secondColor); */
}
.header-cart-right img{
  width: 22px;
  height: auto;
  margin-right: 8px;


}
.header-cart-right:hover .dropdown-menu {
  display: grid;
  margin-top: 0; /* Remove margin so it aligns properly */
}
/* .header-cart-right .dropdown-item{
  width: 20px;
 
} */

.header-cart-right:hover .dropdown-menu img{
 
  width: 20px;
  margin-right: 8px;
  
}

.align-center {
  align-items: center;
}

.font-700 {
  font-weight: 700;
}

.cart-order {
  background: var(--secondColor);
  color: var(--secondText);
  padding: 4px 5px;
  border-radius: 50%;
  font-size: 12px;
  margin-left: -20px;
  height: 25px;
  width: 25px;
  text-align: center;
  position: absolute;
}

.search-main {
  background: #fff;
  border-radius: 26px;
  padding: 0px !important;
}

.search-main select {
  border: none;
  margin-left: 12px;
  padding: 11px 2px;
  border-radius: 26px;
}

.search-main input {
  border: none;
  border-left: 1px solid #0000004d;
  margin-left: 10px;
  padding-left: 10px;
}

.search-main button {
  background: var(--secondColor);
  border: none;
  float: right;
  border-radius: 0px 26px 26px 0px;
}

.search-main button:hover {
  background: #000 !important;
}

.search-main input:focus-visible {
  outline: none !important;
}

.search-main select:hover {
  outline: none !important;
}

.small-txt p {
  font-size: 14px;
}

.banner__item:hover::before {
  right: 50%;
  left: 50%;
  background: rgba(255, 255, 255, 0.2);
}

.our-features span {
  color: #000;
  font-size: 13px;
  line-height: 2px !important;
}

.our-features {
  border: 1px solid var(--themeMain);
  align-items: center;
}

.our-features p {
  margin-bottom: 0px !important;
}

.r-border {
  border-right: 1px solid var(--themeMain);
}

.our-features i {
  color: var(--secondColor);
}

.marque-text {
  background-color: var(--secondColor);
}
.marque-text p span {
  font-weight: 900;
}
.marque-text p {
  font-size: 16px;
  padding: 5px 0px 2px 0px;
  color: var(--themeMain);
}

.cursor-auto {
  cursor: auto;
}

.logo {
  width: 45% !important;
  /* margin-left: 20%; */
}

/* search bar */
.search {
  width: 100%;
  position: relative;
  display: flex;
  
}

.searchTerm {
  width: 100%;
  border: 3px solid ;
  border-right: none;
  padding: 5px;
  border-radius:20px;
  outline: none;
  color: black;
  border:solid #f0f0f0;
  height: 40px;
  
}

.searchButton {
  width: 107px;
  height: 40px;
  border: 1px solid;
  position:absolute;
  text-align: center;
  color:white;
  cursor: pointer;
  font-size: 20px;
  border-radius:20px;
  background-color: var(--secondColor);
  right: 0;
}
.search-font{
  font-size: 16px;
}


.wrap {
  width: 30%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cat-main img {
  width: 70px;
  height: 78px;
 
  border-radius: 20%;
}
.show-more-btn{
  background-color: white;
  color: var(--secondColor);
  border: 1px solid var(--secondColor);
  border-radius: 20px;
  font-weight: bold;
  font-family: Yu Gothic UI Semibold;
}
.show-more-btn:hover{
  background-color: none;
}
.icon-circle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px; /* Adjust size as needed */
  height: 30px; /* Adjust size as needed */
  border-radius: 50%;
  background-color:var(--secondColor); /* Background color for the circle */
  margin-right: 8px; /* Space between icon and text */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */

}
.header-main Button:hover{
  background: white;
  color: var(--secondColor);
  border: 1px solid var(--secondColor);
}
.icon-circle i {
  color: white; /* Change arrow color to white */
  animation-duration: 1.9s; /* Duration of the animation */
  animation-timing-function: ease-in-out; /* Smooth animation */

}

.animate-down {
  animation-name: moveDown; /* Define the animation name */
  animation-iteration-count: infinite; /* Repeat the animation infinitely */
}

.animate-up {
  animation-name: moveUp; /* Define the animation name */
  animation-iteration-count: infinite; /* Repeat the animation infinitely */
}

@keyframes moveDown {
  0% {
    transform: translateY(0); /* Start at original position */
  }
  50% {
    transform: translateY(7px); /* Move down by 10 pixels */
  }
  100% {
    transform: translateY(0); /* Move back to original position */
  }
}

@keyframes moveUp {
  0% {
    transform: translateY(0); /* Start at original position */
  }
  50% {
    transform: translateY(-10px); /* Move up by 10 pixels */
  }
  100% {
    transform: translateY(0); /* Move back to original position */
  }
}


.align-center {
  text-align: center;
}

.align-item-center {
  align-items: center;
}


@media screen and (min-width: 768px) {
  .slider-web-size {
    height: 300px !important;
  }

  .float-right-lg {
    float: right;
  }
  

  .hide-lg-window {
    display: none !important;
  }
  
 

}

.theme-main-btn {
  background: var(--themeMain);
}


.cat-tab {
 
  
  transition: box-shadow 0.3s ease;
  border-radius: 8px;
}
.cat-main{
 display: flex;
 justify-content: center;
}
.catagories-bg{
  background: #f2efef;
}

@media screen and (max-width: 600px) {
  .header-cart-right {
    font-size: 20px;
    padding: 0px 0px;
    margin: 0px;
  }

  .userCart:before {
    font-size: 20px;
    padding: 0px 6px;
    margin: 5px;
  }

  .slider-web-size {
    height: 80px !important;
  }
  
  .cat-tab {
   
    border-radius: 7px;
    padding: 3px 0px;
    
  }
  
  .cat-main{
    
    display: flex;
    justify-content: center;
   
  }
  .catagories-bg{
    background: #f2efef;
  }
  
  

  .marque-text {
    border-radius: 0px 0px 17px 17px;
  }

  .review i {
    font-size: 21px !important;
    margin-bottom: 0px !important;
  }

  .review p {
    font-size: 13px !important;
  }
 
  .logo {
    width: 100% !important;
    /* margin-right: 20%; */
   
  }
  .update-btn, .add-btn {
    padding: 15px 0;
    z-index: 1000;
    
      position: fixed;
      bottom: 0;
      left: 0;
  }
  .btn-my img {
    position: relative;
    float: right;
    bottom: 30px;
    height: 30px;
    width: auto;
    object-fit: cover;
}
  .accordion-item button{
   font-size: 12px;
    
  }
  .filter-button button{
    font-size: 14.7px;
    height: 40px;
  }
  #controlicons{
    margin-right: 20px;
    justify-content: space-between;
  }
 #bottomadd{
  bottom: 0;
  position:fixed;
  z-index: 1000;
  width: 100%;
  padding: 15px;
  right: 1px;
  background-color: var(--secondColor);
 }
 #person{
  bottom: 0px;
  position:fixed;
  z-index: 1000;
  width: 100%;
  padding: 15px;
  right: .5px;
  background-color: var(--secondColor);
 }

 
 #fixed-image {
  position: fixed;
  top: 90px;
  z-index: 1000;
}

.reel-container {
  position: relative;
  height: 100vh; /* Ensure full viewport height */
  overflow: hidden; /* Hide overflow to keep layout clean */
}
.search {
  position: relative;
  left: 5px;
width: 97%;
margin-top: 4px;
margin-bottom: 10px;
}

  .justify-content-end-mbl {
    justify-content: end;
  }

  .mbl-cntr {
    justify-content: center !important;
  }

  .mbl-hide {
    display: none !important;
  }

  .mbl-padding-0 {
    padding: 0px !important;
  }

  .mbl-margin-0 {
    margin: 0px !important;
  }

  .cat-main img {
    width: 80x !important;
    height: 78px !important;
  }

  .cat-main p {
    font-size: 10px !important;
    
  }
  

  /* .cat-main{
        padding: 4px !important;
    } */
}
#webslider{
max-block-size: fit-content;
  border-radius: 20px;
  background-size: contain;
}
.imgbanner{
  max-height: 600px !important;
  border-radius: 20px;
  background-size: contain;
}
/* img animation */
.banner__item {
  position: relative;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  overflow: hidden;
}

.banner__item::after,
.banner__item::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  -webkit-transition: all 0.4s linear;
  -moz-transition: all 0.4s linear;
  -ms-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.w-img img {
  width: 100%;
}

.banner__item::after,
.banner__item::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  -webkit-transition: all 0.4s linear;
  -moz-transition: all 0.4s linear;
  -ms-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.popup-product {
  width: 100px;
}

.me-left-auto {
  margin-left: auto;
}

.navbar-nav a {
  color: #fff !important;
}

.mbl-btn-menu {
  background: #fff;
}

/* slider */
.slick-prev:before,
.slick-next:before {
  color: #074774 !important;
}

/* Review */
.main-review {
  padding: 10px;
}

.review p {
  font-size: 15px;
  color: var(--secondText);
  word-break: break-word;
}

.review-user-details {
  margin-top: -48px;
  text-align: center;
  background: var(--themeMain);
  color: var(--firstText);
  padding: 3px;
  border-radius: 25px;
}

.review {
  padding: 14px 20px 35px 20px;
  margin: 20px;
  box-shadow: 0px 1px 12px -3px var(--themeMain);
  min-height: 200px;
}

.review i {
  color: var(--themeMain);
  font-size: 40px;
  margin-bottom: 11px;
}

.review-user-details p,
strong {
  color: var(--secondColor);
}



/* checkout */
.user-new-address {
  box-shadow: 2px 0px 5px -1px;
  padding: 10px 0px;
  /* min-height: 140px; */
  align-items: center !important;
  text-align: center;
}

.service-centers {
  box-shadow: 2px 0px 5px -1px;
  padding: 20px;
  min-height: 140px;
}

.user-new-address i {
  font-size: 50px;
  background: var(--themeMain);
  border-radius: 50%;
  color: var(--bs-body-bg);
  padding: 0px 3px;
}

.address-flex {
  display: flex;
  align-items: self-start;
}

.userAddress {
  /* box-shadow: 2px 0px 5px -1px; */
  /* padding: 20px; */
  min-height: 140px;
}

.full-width-popup {
  background-color: var(--firstText);
}

.modal-fullscreen {
  width: 100%;
  height: 100%;
}

.userAddress input {
  margin: 3px 7px;
}

.userAddress-lable {
  width: 100%;
  cursor: pointer;
  border-radius: 20px;
}

.userAddress-lable img {
  width: auto;
}

.username-action span {
  float: right;
  font-size: 15px;
}

.address-edit {
  float: right;
  background: green;
  color: #fff !important;
  padding: 7px 10px;
  border-radius: 50%;
}

.img-width-100 {
  width: 100px;
}

.tab-btn {
  background: var(--themeMain) !important;
  border-radius: 0px 10px 10px 0px !important;
  padding: 6px;
  margin-bottom: 7px;
  color: #fff !important;
}

.review-status {
  background: var(--themeMain);
  color: var(--bs-body-bg);
  padding: 10px 10px;
}

.rang-dsign span {
  font-weight: 700;
  margin-top: 3px;
}

.subproduct-card {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  min-height: 90px;
}

.subproduct-card h5 {
  color: var(--bs-black);
  font-weight: 600;
}

.subproduct-card p {
  font-size: 16px;
}

.sub-product-price {
  color: var(--secondText);
}

.sub-product-price .old {
  color: var(--specialPrice);
  text-decoration: line-through;
}

.selectedProductBg {
  background-color: #000;
}

.popup-spacing {
  padding: 10px;
}

.section-divider {
  border-top: 5px solid !important;
  color: var(--secondColor);
}


.your-cart {
  color: var(--secondColor);
}

.card-bg {
  background-color: var(--darkblue);

}

.w-100px {
  width: 100px;
}

.orders-details-printing {
  height: 100%;
  overflow: scroll;
}

@media print {
  .print-hide {
    visibility: hidden;
  }

  .print-enable {
    display: block !important;
  }

  .header-main {
    display: none;
  }

  footer {
    display: none;
  }

  .search {
    display: none;
  }

  

  .main-print-conatiner {
    margin: 0px !important;
    width: 100% !important;
  }
 
}

.product-btns button {
  width: 86%;
}
.quantity-control {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 200px;
  
}

.qty-box {
  width: 80px;
  height: 40px;
  border: 1px solid #ddd;
 
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.qty-input {
  border-left: none;
  border-right: none;
  width: 70px;
  text-align: center;
  font-size: 25px;
  box-shadow: none;
  background-color: #ffffff;
  border-radius: 0;
  color: black;
  height: 40px;
}

.minus-box {
  border-radius: 8px 0 0 8px;
  background-color:#0B2F9F ;
}

.plus-box {
  border-radius: 0 8px 8px 0;
  background-color:  darkgreen;
}

.qty-box:hover {
  background: linear-gradient(135deg, #e6e6e6, #d6d6d6);
  transform: translateY(-2px);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
}

.qty-box:active {
  background: linear-gradient(135deg, #cccccc, #bbbbbb);
  transform: translateY(0);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.cart-badge {
  background: linear-gradient(45deg, #442129, #ccbfbd);
  color: white;
  font-size: 13px;
  padding: 1px 8px;
  border-radius: 50%;
  position: absolute;
  top: -8px;
  font-weight: bold;
  z-index: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: badge-bounce 0.5s ease-in-out infinite alternate;
}
.accordion-item {
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  

}
.subcat label {
  background-color: #f9f9f9;
  padding: 5px;
  border-radius: 4px;
}
.subcat input:checked + label {
  background-color: #007bff;
  color: #fff;
}
.form-range {
  background: linear-gradient(to right, #007bff 0%, #ddd 100%);
}
.form-range::-webkit-slider-thumb {
  background-color: #007bff;
  border-radius: 50%;
}
.clear-btn {
  background-color: #ff6b6b;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
}
.clear-btn:hover {
  background-color: #ff4a4a;
}
.filter-button button{
  background-color: green;
}
@media screen and (min-width: 1200px) {
.reel-container {
  position: relative;
  height: 100vh; 
  top: 0;
  overflow: hidden; 
}

.horizontal-view .reel-view-horizontal {
  display: flex;
  overflow-x: auto;
  height: 70vh; 
  align-items: center;
  padding: 0 10px; 
  margin: 0; 
}

.horizontal-view .reel-item {
  flex: 0 0 auto;
  width: 16vw; 
  height: 65vh; 
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 10px;
  margin-top: 0; 
  border: 1px solid black;
  border-radius: 15px;
}

.horizontal-view .reel-item video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.horizontal-view .reel-overlay {
  position: absolute;
  bottom: 5%;
  left: 5%;
  color: white;
  font-size: 14px;
  background: rgba(0, 0, 0, 0.5);
  padding: 5px; 
  display: none;
}

.horizontal-view .view-toggle {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #fff;
  border: none;
  padding: 8px;
  cursor: pointer;
  z-index: 10;
}

.vertical-view .reel-view-vertical {
  height: 100vh;
  overflow-y: scroll; 
  scroll-snap-type: y mandatory; 
  scroll-behavior: smooth;
  position: fixed;
  z-index: 1000;
  top:0;
  right: 1px;
  width: 100%;
  bottom: 20px;
  background:#b1a8a8;
  display: block;
  justify-content: center;
 
  
}

.vertical-view .reel-item {
 left: 30%;
  height: 100vh; 
  width: 40%;
  margin-bottom: 0; 
  scroll-snap-align: start;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 10px 0px 0;
  scroll-snap-align: start;
}

.vertical-view .reel-item video {
  width: 80%;
  height:100%;
  object-fit: cover;
  
}

.vertical-view .view-toggle {
  position: absolute;
  top:1px;
  right: 10px;
  background: var(--themeMain);
  color: white;
  border: none;
  padding: 18px;
  cursor: pointer;
  z-index: 10000;
}
.vertical-view .reel-overlay {
  position: absolute;
  bottom: 10%;
  left: 15%;
  color: white;
  font-size: 16px;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
}
.vertical-view .buy-now-button {
  background: #ff5722;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 10px;
  cursor: pointer;
  border-radius: 5px;
 
}

.vertical-view .buy-now-button:hover {
  background: #e64a19;
}
}
@media screen and (max-width: 600px) {

.reel-container {
  position: relative;
  height: 100vh; 
  top: 0;
  overflow: hidden; 
}
.product-details-page{
  padding-bottom: 50px; /* Add extra padding for this page */
}

.horizontal-view .reel-view-horizontal {
  display: flex;
  overflow-x: auto;
  height: 70vh; 
  align-items: center;
  padding: 0 10px; 
  margin: 0; 
}

.horizontal-view .reel-item {
  flex: 0 0 auto;
  width: 45vw; 
  height: 45vh; 
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 10px;
  margin-top: 0; 
  border: 1px solid black;
  border-radius: 15px;
}

.horizontal-view .reel-item video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.horizontal-view .reel-overlay {
  position: absolute;
  bottom: 5%;
  left: 5%;
  color: white;
  font-size: 14px;
  background: rgba(0, 0, 0, 0.5);
  padding: 5px; 
  display: none;
}

.horizontal-view .view-toggle {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #fff;
  border: none;
  padding: 8px;
  cursor: pointer;
  z-index: 10;
}

.vertical-view .reel-view-vertical {
  height: 98vh;

  overflow-y: scroll; 
  scroll-snap-type: y mandatory; 
  scroll-behavior: smooth;
  position: fixed;
  z-index: 1000;
  top:0px;
  right: 1px;
  width: 100%;
  bottom: 0px;
  background:#000;
  
}

.vertical-view .reel-item {
  height: 98vh; 
  margin-bottom: 0; 
  width: 100%;
  scroll-snap-align: start;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 20px 0px 0;
  scroll-snap-align: start;
}


.vertical-view .reel-item video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  
}

.vertical-view .view-toggle {
  position: absolute;
  top:0;
  right: 10px;
  background: var(--themeMain);
  color: white;
  border: none;
  padding: 8px;
  cursor: pointer;
  z-index: 10000;
}
.vertical-view .reel-overlay {
  position: absolute;
  bottom: 10%;
  left: 5%;
  color: white;
  font-size: 16px;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
}
.vertical-view .buy-now-button {
  background: #ff5722;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 10px;
  cursor: pointer;
  border-radius: 5px;
 
}

.vertical-view .buy-now-button:hover {
  background: #e64a19;
}

}
/* #single{
  position: relative;
  bottom:180px;
} */
/* Container Styles */
.order-status-container {
  position: relative;
 
  overflow: hidden;
  color: #fff;
  padding: 2rem;
}

.particle-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;
  pointer-events: none;
}

.particle-background::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255,255,255,0.1) 20%, rgba(0,0,0,0) 60%);
  animation: particles 10s infinite;
  transform: translate(-50%, -50%);
}

@keyframes particles {
  0% { background-position: 0% 0%; }
  100% { background-position: 100% 100%; }
}

.text-shadow {
  text-shadow: 4px 4px 8px rgba(0,0,0,0.5);
  animation: textGlow 1.5s ease-in-out infinite alternate;
}

@keyframes textGlow {
  from {
    text-shadow: 0 0 5px rgba(255,255,255,0.5);
  }
  to {
    text-shadow: 0 0 20px rgba(255,255,255,1);
  }
}

/* Button Styles */
.custom-button {
  border-radius: 50px;
  font-weight: bold;
  font-size: 15px;
  background: linear-gradient(45deg, #000000, #807973);
  border: none;
  color: #fff;
  box-shadow: 0 6px 12px rgba(0,0,0,0.4);
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  z-index: 2;
}

.custom-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  background: rgba(255,255,255,0.2);
  transition: opacity 0.3s ease;
  z-index: -1;
}

.custom-button:hover::before {
  opacity: 0;
}

.custom-button:hover {
  background: linear-gradient(45deg, #feb47b, #ff7e5f);
  transform: scale(1.1);
  box-shadow: 0 8px 16px rgba(0,0,0,0.6);
}


.button-group {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
/* .banner-shop img{
  height: 150px;
} */
@media screen and (min-width: 1200px) {
.yyyy{
  margin-left: 30px;
}
}
.pagination {
  display: flex;
  list-style: none;
  justify-content: center;
  padding: 0;
}
.pagination li {
  margin: 0 5px;
}
.pagination li a {
  border-radius: 50%;
  padding: 8px 15px;
  background-color: #f0f0f0;
  color: var(--themeMain);
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.pagination li a:hover {
  background-color: var(--secondColor);
  color: #fff;
  transform: scale(1.05);
}
.pagination li.active a {
  background-color: var(--themeMain);
  color: #fff;
  border: 2px solid var(--secondColor);
}

.last-category {
  display: flex;
  justify-content: center; /* Centers the last category */
  order: 0; /* Optional: Adjust order if needed */
}



/* Optional: If you want to make the last category stand out */
.personalize-btn-mbl {
  position: fixed;
  bottom: 0px;
color: white;

  transform: translateX(-50%);
  background-color: #198754;
  animation: pulse 4.3s infinite; 
  z-index: 1000;
  
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.btn-my img{
  height: 30px;
  width: auto;
}
.order{
  font-family: 'Times New Roman', Times, serif;
}
.order button, input[type="number"] {
  background: #e0e0e0;
  border-radius: 12px;
  box-shadow: 8px 8px 15px #bebebe, -8px -8px 15px #ffffff;
  transition: all 0.3s ease-in-out;
}
.order button{
  background: #eb3446;
  border-radius: 12px;
  box-shadow: 8px 8px 15px #bebebe, -8px -8px 15px #ffffff;
  transition: all 0.3s ease-in-out;
}


.order button:hover, input[type="number"]:hover {
  box-shadow: inset 8px 8px 15px #bebebe, inset -8px -8px 15px #ffffff;
}

.order button.minus, button.plus {
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #f0f0f3;
  border-radius: 50%;
  box-shadow: 5px 5px 10px #ccc, -5px -5px 10px #fff;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.order button.minus:hover, button.plus:hover {
  transform: scale(1.1) rotate(20deg);
  background: #ffffff;
}

.order input.w-70 {
  width: 60px;
  height: 40px;
  text-align: center;
  border: none;
  box-shadow: inset 4px 4px 8px #bebebe, inset -4px -4px 8px #ffffff;
}


.order .your-cart {
  font-size: 1.2rem;
  color: #012246;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  padding: 10px 20px;
  border-radius: 12px;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
}


.order table {
  width: 100%;
  /* border-collapse: separate; */
  border-spacing: 0 10px;
}

.order table thead th {
  background: linear-gradient(145deg, #ffffff, #f0f0f0);
  box-shadow: 2px 2px 6px rgba(0,0,0,0.1), -2px -2px 6px rgba(255,255,255,0.8);
  padding: 10px;
  border-radius: 8px;
  color: #333;
}

.order table tbody tr {
  background: #ffffff;
  box-shadow: 4px 4px 10px rgba(0,0,0,0.1), -4px -4px 10px rgba(255,255,255,0.9);
  transition: transform 0.2s ease;
}

.order table tbody tr:hover {
  transform: scale(1.02);
}

table tbody td {
  padding: 15px;
  text-align: center;
}

.discount-price {
  text-decoration: line-through;
  color: #cc0c39;
}


.order button.theme-btn {
  background: linear-gradient(145deg, #ff8e01, #ff7c00);
  color: white;
  border-radius: 25px;
  padding: 15px;
  font-weight: bold;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.7);
  transition: all 0.3s ease;
  border: 1px solid whitesmoke;
}

.order button.theme-btn:hover {
  background: linear-gradient(145deg, #ff7c00, #ff6b00);
  box-shadow: inset 3px 3px 8px rgba(0, 0, 0, 0.2), inset -3px -3px 8px rgba(255, 255, 255, 0.5);
  transform: translateY(-2px);
}


.spinner-border {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid var(--themeMain);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@media screen and (max-width: 600px) {
  table tbody td {
    padding: 1px;
    text-align: center;
  }
}
.address {
  padding: 20px;
  background-color: var(--grayBg);
  font-family: 'Times New Roman', Times, serif;
  
}

.address .section__title h2 {
  color: var(--themeMain);
  font-weight: bold;
}

.address .theme-btn {
  background-color: var(--secondColor);
  color: var(--firstText);
  border-radius: 50px;
  padding: 10px 10px;
  font-size: 13px;
  transition: background-color 0.3s ease;
  border: 1px solid whitesmoke;
  position: relative;
  top: 5px;
}

.address .theme-btn:hover {
  background-color: var(--themeMain); /* Theme color on hover */
  color: var(--firstText);
 
}

.address .border, .address .section__head, .address .address-card {
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.address .text-success, .congratulations {
  color: var(--success);
  font-weight: bold;
  font-size: 1.1rem;
}

.address .text-danger, .total-amount {
  color: var(--specialPrice);
  font-weight: bold;
}

.address.email-input {
  border-radius: 10px;
  border: 1px solid var(--themeMain);
}

.address .border-success {
 
  border: 1px solid darkgreen;
}
.address .border-danger {
  border: 1px solid red;
}

.address .address-card:hover {
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  transform: scale(1.01);
  transition: all 0.3s ease-in-out;
}


.address .shadow {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
}

/* .address .cursor-pointer:hover {
  background-color: var(--grayBg);
} */
@media screen and (max-width: 600px) {
 
  .margin{
    margin-top: 160px;
  }
}
.thanku {
  font-family: 'Arial', sans-serif;
  background: linear-gradient(135deg, #e2e2e2 25%, #ffffff 75%);
  margin: 0;
  padding: 20px;
}

.container3 {
  max-width: 100%;
  margin: auto;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  padding: 30px;
  position: relative;
  overflow: hidden;
}
.container3 .tname{
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: x-large;
  text-align: center;
}

.header3 {
  text-align: center;
  margin-bottom: 20px;
}

.header3 h1 {
  color: #1abc9c;
  font-size: 2rem; /* Larger font size for emphasis */
}

.thank-you-message {
  text-align: center;
  font-size: 1.2rem; /* Slightly larger font size */
  color: #34495e;
  margin-bottom: 20px; /* Space below the message */
}

.thanku h2 {
  color: #e67e23;
  margin-bottom: 15px;
  border-bottom: 3px solid #e67e23;
  padding-bottom: 10px;
  font-size: 1.5rem;
}

.order-summary3 {
  margin-top: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.summary-item3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #dcdcdc;
}

.summary-item3:last-child {
  border-bottom: none;
}

.summary-details3 {
  flex-grow: 1;
  padding-right: 10px;
}

.summary-details3 strong {
  display: block;
  color: #34495e;
  font-size: 1.2rem;
}

.summary-item3 img {
  width: 90px;
  height: auto;
  border-radius: 8px;
  border: 2px solid #1abc9d;
  transition: transform 0.3s ease;
}

.summary-item3 img:hover {
  transform: scale(1.05);
}

.total3 {
  font-weight: bold;
  font-size: 1.5rem;
  color: #e74c3c;
}

.map-container3 {
  position: relative;
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

iframe {
  width: 100%;
  height: 250px;
  border: 0;
  border-radius: 10px;
}

.shipping-info3 {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 15px;
  border-radius: 9px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
}

.billing-info3 {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  margin-top: 15px; /* Ensure proper spacing */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.card3 {
  background-color: #dfe6e9;
  padding: 15px;
  border-radius: 8px;
  margin-top: 15px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}

.card3 i {
  margin-right: 10px;
  color: #1abc9c; /* Icon color */
  font-size: 1.5rem;
}

@media (max-width: 600px) {
  .summary-item3 {
      flex-direction: column;
      align-items: flex-start;
  }

  .summary-item3 img {
      margin: 10px 0;
  }
}
.offercoupon{
 
    float:right;
    color: #000;
    border-radius: 10px;
    font-weight: bolder;
    border:1px solid whitesmoke;
    bottom:32px;
    position:relative;
    left: 10px;
    font-size:15px;
    top: 5px;
 
}
.tab-content{
/* box-shadow: #e1e1e1 -5px 10px 10px 5px; */
border-radius: 15px;
 }
.summery2{
  font-size: 20px;
  text-shadow: 8px 8px 8px rgba(228, 17, 17, 0.5);
  animation: pulse 2.5s ease-in-out infinite ;
}
.totalsum{
  font-size: 18px;
}
/* Timeline.css */
.bgtimeline {
  font-family: Arial, sans-serif;
  background-color: #f3f4f6;
  color: #333;
  margin: 0;
  padding: 20px;
}

.timeline {
  position: relative;
  padding-left: 30px;
}

.timeline-entry {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  position: relative;
}

.timeline-entry::before {
  content: '';
  width: 2px;
  height: 100%;
  background-color: #d1d5db;
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
}

.timeline-icon {
  width: 20px;
  height: 20px;
  background-color: #333;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  margin-right: 10px;
  position: relative;
  z-index: 1;
}

.timeline-content .title {
  font-weight: bold;
}

.timeline-content .recipient {
  font-size: 0.9em;
  color: #555;
}

.timeline-content .time {
  font-size: 0.8em;
  color: #888;
  margin-top: 4px;
  text-align: right;
}

.view-email-button {
  display: inline-block;
  padding: 4px 8px;
  background-color: #0077cc;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 0.9em;
  margin-top: 10px;
  cursor: pointer;
}

.view-email-button:hover {
  background-color: #005fa3;
}
.timeline-entry2 {
  display: flex;
  align-items: flex-start;
  margin: 15px 0;
  padding: 10px;
  border-radius: 8px;
  background: var(--grayBg); /* Light background for each entry */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.stylish-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--secondColor); /* Vibrant background color for icon */
  color: var(--firstText); /* White text color */
  font-size: 20px;
  font-weight: bold;
  margin-right: 15px; /* Space between icon and content */
}

.stylish-content {
  flex: 1; /* Take remaining space */
}

.user-name {
  font-size: 1.1em;
  font-weight: 600; /* Bold user name */
  color: var(--secondText); /* Dark text color */
}

.comment-title {
  font-size: 0.95em;
  margin: 5px 0;
  color: var(--secondText);
}

.recipient {
  font-size: 0.85em;
  color: var(--grayText); /* A muted color for email and time */
}

.time-stamp {
  margin-top: 5px; /* Space above the timestamp */
  font-style: italic; /* Italic style for the time */
}